import React from 'react';
import img from '~/components/Layout/img/argumentsInEvidence.jpg';

const Background = (props) => (
  <div
    className="argumentInEvidence-background"
    style={{ backgroundImage: `url(${img})` }}
    {...props}
  />
);

export default Background;
